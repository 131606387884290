.Concat{
  position: fixed;
  right:0;
  top:20%;
  z-index: 99;
  box-shadow: rgba(255,75,75,0.5) 0 0 8px 2px;
  @media screen and(max-height:500px){
    -webkit-transform-origin:100% 0 0;
    -moz-transform-origin: 100% 0 0;
    -ms-transform-origin: 100% 0 0;
    -o-transform-origin: 100% 0 0;
    transform-origin: 100% 0 0;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform:scale(0.8);
    -o-transform: scale(0.8);
    transform:scale(0.8);
  }
  .ConcatSmall{

    background-color: #fe5d03;
    padding:5px 8px;
    cursor: pointer;
  }
  .ConcatMessageIcon{
    width: 20px;
    height:auto;
  }
  .ConcatWord{
    width: 20px;
    text-align: center;
    font-size: 18px;
  color: white;
  }
  .ConcatBig{
    border: 2px solid #fe5d03;
    background-color: white;
    position: relative;


  }
  .ConcatHead{
    background-color:#fe5d03;
    color: white;
    padding:0 5px;
    height:28px;
    line-height: 28px;
    vertical-align: middle;
   }
  .ConcatWordBig{
    display: inline-block;
    width: auto;
    letter-spacing: 5px;
    font-size: 17px;
    margin-left:10px;
  }
  .ConcatBody{
    text-align: center;
    padding:0 5px;
  }
  .ConcatImg{
    width: 120px;
    height:auto;

  }
  .ConcatBigPhone{
    width: 140px;
    text-align: center;
    color:#666666;
    font-size: 14px;
    border-bottom: 1px solid #dddddd;
    margin:10px 0;
    span{
      color:#fe5d03;
      font-size: 17px;
    }
  }
  .messageToUs{
    color: #ff9b56;
    cursor: pointer;
  }
  .course{
    cursor: pointer;
  }
  .gzhImgs{
    margin-top:10px;
    padding-top:10px;
    height:120px;
    border-top: 1px solid #dddddd;
  }
  .soWeChat{
    margin-top:10px;
     .SoWeKeFuWord{
       color: #ff9b56;
     }
    .SoWeChatNow{
      cursor: pointer;
      color:#fff;
      height: 35px;
      line-height: 35px;
      width: 80%;
      margin:5px auto;
      border-radius: 5px;
      background: -moz-linear-gradient(138deg, #fdcdac 0%, #fe5d03 100%);
      background: -webkit-linear-gradient(138deg,  #fdcdac 0%,#fe5d03 100%);
      background: -o-linear-gradient(138deg, #fdcdac 0%,#fe5d03 100%);
      background: -ms-linear-gradient(138deg, #fdcdac 0%,#fe5d03 100%);
      background: linear-gradient(138deg, #fdcdac 0%,#fe5d03 100%);
      &:hover{
        opacity: 0.8;
      }
      img{
        height:30px;
        width: auto;
        display: inline-block;
        margin-right:5px;
      }
    }
  }
  .weChatCoverFa{
    position: absolute;
    right:160px;
    width: 200px;
    top:0;
    text-align: center;
    animation: leftIn 1s ease;
    -moz-animation: leftIn 1s ease;
    -o-animation: leftIn 1s ease;
    -webkit-animation: leftIn 1s ease;
    z-index: 999;
    @media screen and (max-width:360px) {
          right:100px;
    }
  }
  .weChatCover{
    width: 100%;
    height:200px;
    background-color: white;
    box-shadow: rgba(0,0,0,0.3) 0 0 10px 2px;
    img{
      width: 100%;
      height:auto;
    }
  }
  .weChatClose{
    display: inline-block;
    margin-top:20px;
    width: 35px;
    height:auto;
    cursor: pointer;
    opacity: 0.9;
    &:hover{
      opacity: 0.7;
    }
  }
}