html,body,#root{
  width:100%;
  height:100%;
  margin:0;
  padding:0;
  font-family:'Microsoft YaHei','Hiragino Sans GB',Helvetica,Arial,'Lucida Grande',sans-serif;
}

#root{
  overflow-scrolling:touch;
  -webkit-overflow-scrolling: touch;
}
div{
  margin:0;

}